import { useMemo } from 'react'

import { REGISTRATION_STEPS } from '../../utils/constants'
import './styles.css'

const RegistrationSteps = ({ currentStep = 1 }) => {
  const Step = ({ step, title, shortDescription, StepIcon, show, activeSteps }) => {
    if (!show) return null

    const stepStatusClassName = useMemo(() => {
      if (activeSteps.includes(currentStep)) {
        return 'currentRegistrationStep'
      } else if (step < currentStep) {
        return 'previousRegistrationStep'
      } else {
        return 'futureRegistrationStep'
      }
    }, [currentStep, step])

    return (
      <div className={`innerStep ${stepStatusClassName}`}>
        <div className="textInfo">
          <p className="stepTitle">{title}</p>
          <p className="stepDescription">{shortDescription}</p>
        </div>
        <div className="innerStepIcon">
          <StepIcon className="stepIcon" size={27} fill="var(--swatch--warm-white)" />
        </div>
      </div>
    )
  }

  return (
    <div className="registrationSteps">
      {REGISTRATION_STEPS.map(({ title, shortDescription, step, Icon, showIcon, ...rest }) => (
        <Step
          {...rest}
          show={showIcon}
          key={step}
          step={step}
          StepIcon={Icon}
          title={title}
          shortDescription={shortDescription}
        />
      ))}
    </div>
  )
}

export default RegistrationSteps

import { useMemo } from 'react'
import { REGISTRATION_STEPS } from '../../../utils/constants'

const FormHeader = ({ currentStep, children }) => {
  const { title, description } = useMemo(
    () => REGISTRATION_STEPS.find(({ step }) => currentStep === step),
    [currentStep]
  )
  const currentStepInfo = useMemo(
    () => REGISTRATION_STEPS.find(({ step }) => currentStep === step),
    [currentStep]
  )

  return (
    <div className="innerHeaderFormRegistration">
      <p className="stepCounter">
        Step {currentStepInfo?.showStepNumber}/
        {REGISTRATION_STEPS.filter(({ showIcon }) => showIcon).length}
      </p>
      <p className="titleForm">{title}</p>
      <p className="descriptionForm">{description}</p>
      {children}
    </div>
  )
}

export default FormHeader

import { routes } from '../router/routes'
import { getAllTags } from '../api/admin/tags'
import {
  getThemeMessages,
  getRepeatedContentMessages,
  deleteRepeatedContentMessage,
} from '../api/admin/messages'
import {
  IconTags,
  IconUsers,
  BookContent,
  VscFeedback,
  SpaceDashboard,
  Coupon,
  CreditCard,
  Settings,
} from '../components/SvgIcon'
import Lock from '../components/SvgIcon/components/Lock'

export const APP_ENVIRONMENT = process.env.REACT_APP_ENV
export const ENABLED_SENTRY = APP_ENVIRONMENT === 'development' || APP_ENVIRONMENT === 'production'
export const IS_STAGING = process.env.REACT_APP_ENV === 'development'
export const API_ROOT = process.env.REACT_APP_API_URL
export const AUTH_TOKEN = 'AUTHORIZATION_TOKEN'
export const SENTRY_URL_DSN =
  'https://fa70bde0466a4296879ab1a17459825a@o391252.ingest.sentry.io/4504332186746880'

export const APP_ELEMENTS = {
  NAME: process.env.REACT_APP_NAME,
  DARK_LOGO: process.env.REACT_APP_DARK_LOGO_SRC,
  LIGHT_LOGO: process.env.REACT_APP_LIGHT_LOGO_SRC,
  SMALL_LOGO: process.env.REACT_APP_SMALL_LOGO_SRC,
}

export const ROLE_GUEST = 'guest'
export const ROLE_USER = 'user'
export const ROLE_ADMIN = 'admin'
export const ROLE_TESTER = 'test'

export const ROLES = {
  ROLE_USER: ROLE_USER,
  ROLE_GUEST: ROLE_GUEST,
  ROLE_ADMIN: ROLE_ADMIN,
  ROLE_TESTER: ROLE_TESTER,
}
export const SIZE_PAGINATE_USERS = 30
export const SIZE_PAGINATE_FEEDBACKS = 10
export const SIZE_PAGINATE_MESSAGES = 20
export const SIZE_PAGINATION_DIALOG_MESSAGES = 100
export const SIZE_PAGINATION_COUPON = 5
export const SIZE_PAGINATION_CHAT_LIST = 100
export const SIZE_PAGINATION_SURVEYS = 13

export const MARGIN_PAGES_DISPLAYED = 2
export const PAGE_RANGE_DISPLAYED = 5
export const SETTINGS_PAGINATION = {
  breakClassName: 'breakDots',
  pageClassName: 'innerItemNumberPagination',
  pageLinkClassName: 'itemNumberPagination',
  activeClassName: 'activePagination',
  previousClassName: 'arrowBtnPagination',
  nextClassName: 'arrowBtnPagination',
  disabledClassName: 'disabledArrow',
  containerClassName: 'paginateBlock',
  marginPagesDisplayed: MARGIN_PAGES_DISPLAYED,
  pageRangeDisplayed: PAGE_RANGE_DISPLAYED,
  renderOnZeroPageCount: null,
}

export const MESSAGE_TIME_FORMAT = {
  sameDay: '[Today] LT',
  lastDay: '[Yesterday] LT',
  lastWeek: '[Last] ddd LT',
  sameElse: 'YYYY-MM-DD LT',
}

export const SUBSCRIPTION_API_ACTIONS = {
  PAUSE: 'pause',
  CANCEL: 'cancel',
  REACTIVATE: 'reactivate',
  CANCEL_IMMEDIATELY: 'cancel_immediately',
}
export const SUBSCRIPTION_API_ACTIONS_INFO = {
  [SUBSCRIPTION_API_ACTIONS.PAUSE]: {
    label: 'Pause',
    successMessages: (themeName) => `${themeName} was paused`,
  },
  [SUBSCRIPTION_API_ACTIONS.CANCEL]: {
    label: 'Cancel',
    successMessages: (themeName) => `${themeName} was canceled`,
  },
  [SUBSCRIPTION_API_ACTIONS.REACTIVATE]: {
    label: 'Reactivate',
    successMessages: (themeName) => `${themeName} was reactivated`,
  },
  [SUBSCRIPTION_API_ACTIONS.CANCEL_IMMEDIATELY]: {
    label: 'Cancel immediately',
    successMessages: (themeName) => `${themeName} was immediately canceled`,
  },
}

export const PRICE_TYPES = {
  RECURRING: 'recurring',
  ONE_TIME: 'one_time',
}
export const PRICE_TYPES_SETTINGS = {
  [PRICE_TYPES.RECURRING]: {
    label: 'Recurring Payment',
    value: PRICE_TYPES.RECURRING,
    description:
      'This plan involves automatic billing on a regular basis (monthly or yearly). A user subscribes and will be billed regularly until unsubscribed.',
  },
  [PRICE_TYPES.ONE_TIME]: {
    label: 'One-Time Payment',
    value: PRICE_TYPES.ONE_TIME,
    description:
      'This plan involves a one-time payment at the time of registration or product purchase. The user pays only once and has no further payment obligations.',
  },
}
export const THEME_SETTINGS_PRICE_TYPES = [
  PRICE_TYPES_SETTINGS[PRICE_TYPES.RECURRING],
  PRICE_TYPES_SETTINGS[PRICE_TYPES.ONE_TIME],
]

export const SUBSCRIPTABLE_TYPES = {
  STRIPE_SUBSCRIPTION: 'stripe_subscription',
  PAID_CONTENTS_SUBSCRIPTION: 'paid_contents_subscription',
  NO_SUBSCRIPTION: 'no_subscription',
}

export const PAYMENT_OPTIONS = {
  [SUBSCRIPTABLE_TYPES.STRIPE_SUBSCRIPTION]: { full: 'yearly', short: 'yr' },
  [SUBSCRIPTABLE_TYPES.PAID_CONTENTS_SUBSCRIPTION]: { full: 'one-time', short: 'one-time' },
  [SUBSCRIPTABLE_TYPES.NO_SUBSCRIPTION]: { full: 'one-time', short: 'one-time' },
}

export const SUBSCRIPTION_STATUSES = {
  TRIAL: 'trial',
  NON_RENEW_TRIAL: 'non_renew_trial',
  ACTIVE: 'active',
  NON_RENEW_ACTIVE: 'non_renew_active',
  CANCELED: 'canceled',
  PAYMENT_FAILED: 'payment_failed',
  PAUSED: 'paused',
  FINISHED: 'finished',
  ABANDONED_CART: 'abandoned_cart',
}
export const SUBSCRIPTION_STATUSES_ORDER = [
  SUBSCRIPTION_STATUSES.TRIAL,
  SUBSCRIPTION_STATUSES.NON_RENEW_TRIAL,
  SUBSCRIPTION_STATUSES.ACTIVE,
  SUBSCRIPTION_STATUSES.NON_RENEW_ACTIVE,
  SUBSCRIPTION_STATUSES.PAUSED,
  SUBSCRIPTION_STATUSES.FINISHED,
  SUBSCRIPTION_STATUSES.CANCELED,
  SUBSCRIPTION_STATUSES.PAYMENT_FAILED,
  SUBSCRIPTION_STATUSES.ABANDONED_CART,
]
export const SUBSCRIPTION_STATUSES_INFO = {
  [SUBSCRIPTION_STATUSES.ACTIVE]: {
    text: 'Active',
    bgColor: 'var(--active-color)',
    color: 'var(--active-color-text)',
    adminDescription: 'The user has an active subscription.',
  },
  [SUBSCRIPTION_STATUSES.PAUSED]: {
    text: 'Paused',
    bgColor: 'var(--pause-color)',
    color: 'var(--pause-color-text)',
    adminDescription: 'The subscription is temporarily paused and can be resumed.',
  },
  [SUBSCRIPTION_STATUSES.ABANDONED_CART]: {
    text: 'Abandoned',
    bgColor: 'var(--abandoned-color)',
    color: 'var(--abandoned-color-text)',
    adminDescription: 'The user abandoned the checkout process before completion.',
  },
  [SUBSCRIPTION_STATUSES.FINISHED]: {
    text: 'Finished',
    bgColor: 'var(--finished-subscription-color)',
    color: 'var(--finished-subscription-color-text)',
    adminDescription: 'The user’s subscription has ended.',
  },
  [SUBSCRIPTION_STATUSES.TRIAL]: {
    text: 'Free Trial',
    bgColor: 'var(--trial-color)',
    color: 'var(--trial-color-text)',
    adminDescription: 'The user is currently in a free trial period.',
  },
  [SUBSCRIPTION_STATUSES.CANCELED]: {
    text: 'Canceled',
    bgColor: 'var(--canceled-color)',
    color: 'var(--canceled-color-text)',
    adminDescription: 'The user has canceled their subscription.',
  },
  [SUBSCRIPTION_STATUSES.PAYMENT_FAILED]: {
    text: 'Payment failed',
    bgColor: 'var(--payment-failed-color)',
    color: 'var(--payment-failed-color-text)',
    adminDescription: 'The user’s payment has failed, and action is needed.',
  },
  [SUBSCRIPTION_STATUSES.NON_RENEW_TRIAL]: {
    text: 'Free Trial',
    bgColor: 'var(--trial-color)',
    color: 'var(--trial-color-text)',
    adminDescription: 'The free trial has been set to not renew after completion.',
  },
  [SUBSCRIPTION_STATUSES.NON_RENEW_ACTIVE]: {
    text: 'Active',
    bgColor: 'var(--active-color)',
    color: 'var(--active-color-text)',
    adminDescription: 'The active subscription will not renew once it expires.',
  },
}

export const ALL_ACTIVE_SUBSCRIPTION_STATUSES = [
  SUBSCRIPTION_STATUSES.ACTIVE,
  SUBSCRIPTION_STATUSES.TRIAL,
]
export const ALL_NON_RENEW_SUBSCRIPTION_STATUSES = [
  SUBSCRIPTION_STATUSES.NON_RENEW_TRIAL,
  SUBSCRIPTION_STATUSES.NON_RENEW_ACTIVE,
]
export const ALL_SUBSCRIPTION_STATUSES_CAN_CANCELLED = [
  ...ALL_ACTIVE_SUBSCRIPTION_STATUSES,
  SUBSCRIPTION_STATUSES.ABANDONED_CART,
]
export const ALL_SUBSCRIPTABLE_TYPES_CAN_CANCELLED = [
  SUBSCRIPTABLE_TYPES.STRIPE_SUBSCRIPTION,
  SUBSCRIPTABLE_TYPES.NO_SUBSCRIPTION,
]

export const USER_HOME_VISIBLE_SUBSCRIPTION_STATUSES = [
  { label: 'All', value: [] },
  {
    label: SUBSCRIPTION_STATUSES_INFO[SUBSCRIPTION_STATUSES.ACTIVE].text,
    value: [
      SUBSCRIPTION_STATUSES.TRIAL,
      SUBSCRIPTION_STATUSES.ACTIVE,
      SUBSCRIPTION_STATUSES.NON_RENEW_TRIAL,
      SUBSCRIPTION_STATUSES.NON_RENEW_ACTIVE,
    ],
  },
  {
    label: SUBSCRIPTION_STATUSES_INFO[SUBSCRIPTION_STATUSES.CANCELED].text,
    value: [SUBSCRIPTION_STATUSES.CANCELED],
  },
  {
    label: SUBSCRIPTION_STATUSES_INFO[SUBSCRIPTION_STATUSES.ABANDONED_CART].text,
    value: [SUBSCRIPTION_STATUSES.ABANDONED_CART],
  },
  {
    label: SUBSCRIPTION_STATUSES_INFO[SUBSCRIPTION_STATUSES.PAUSED].text,
    value: [SUBSCRIPTION_STATUSES.PAUSED],
  },
  { label: 'Completed', value: [SUBSCRIPTION_STATUSES.FINISHED] },
]

export const CANCEL_DESCRIPTION_USER_TEXT = {
  [SUBSCRIPTION_STATUSES.ABANDONED_CART]: () => (
    <>
      It looks like you’ve started your subscription process but didn’t complete the payment. Your
      details are saved, but to access main content, please finish your registration. <br /> You
      want to STOP receiving messages.
    </>
  ),
  DEFAULT: ({ cancelAt }) => (
    <>
      If you cancel now, your subscription will remain active until{' '}
      <span className="text-nowrap fw-bold">{cancelAt}</span>
      , which is the last date you've paid for. After that, you'll lose access to all premium
      features and content. <br /> You won't be charged again.
    </>
  ),
}
export const CANCEL_DESCRIPTION_ADMIN_TEXT = {
  [SUBSCRIPTION_STATUSES.ABANDONED_CART]: () => (
    <>
      The user has started the subscription process but hasn't completed the payment. Their details
      are saved, but they do not have access to the main content. <br />
      Confirm to stop sending any further messages to the user.
    </>
  ),
  DEFAULT: ({ cancelAt, action }) =>
    action === SUBSCRIPTION_API_ACTIONS.CANCEL_IMMEDIATELY ? (
      <>
        The user’s subscription is set to not renew but is still active. <br />
        Confirming this action will immediately cancel the subscription, and the user will lose
        access to all premium features and content right away. The user will not be charged further.{' '}
        <br />
        Are you sure you want to proceed with this immediate cancellation?
      </>
    ) : (
      <>
        By confirming cancellation, the user’s subscription will remain active until{' '}
        <span className="text-nowrap fw-bold">{cancelAt}</span>, the last paid date. After this
        date, the user will lose access to all premium features and content. <br /> The user will
        not be charged further. Are you sure you want to proceed with this cancellation?
      </>
    ),
}

export const MEDITATION_SLIDER_SETTINGS = {
  speed: 500,
  fade: true,
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
}

export const HISTORY_BACK = -1

export const ERROR_EXPIRED_TOKEN =
  'E_JWT_TOKEN_EXPIRED: The jwt token has been expired. Generate a new one to continue'
export const ERROR_INVALID_TOKEN = 'E_INVALID_JWT_TOKEN: invalid signature'
export const ERROR_NETWORK = 'Network Error'

export const ERROR_CODE_NOT_FOUND = 404
export const ERROR_CODE_VALIDATION = 422
export const ERROR_CODE_BAD_REQUEST = 400
export const ERROR_CODE_THROTTLING = 429
export const ERROR_CODE_SERVER_ERROR = 500

export const ERROR_ALREADY_EXISTS_CODE = 10003
export const ERROR_VERIFICATION_CODE = 10015

export const WARNING_MESSAGE_VALIDATE_VARIABLES = 'You have forgotten to close a variable.'

export const CHART_TYPE_DELIVERED_MESSAGES = 'delivered_messages'
export const CHART_TYPE_USERS = 'users'

export const BUTTONS_DASHBOARD_TYPE_CHARTS = [
  { title: 'SMS', chartType: CHART_TYPE_DELIVERED_MESSAGES },
  { title: 'Users', chartType: CHART_TYPE_USERS },
]

export const DEFAULT_DATE_USER_PHONE_LIST = { enteredBillingInfo: [], accountConfirmed: [] }

export const DEFAULT_SETTINGS_LINE_CHART = {
  width: 500,
  height: 300,
  margin: {
    top: 30,
    right: 0,
    left: -20,
    bottom: 5,
  },
  Line: true,
}

export const DEFAULT_SETTINGS_XAxis = {
  tickSize: 0,
  tickMargin: 15,
  dataKey: 'date',
  padding: { left: 10, right: 10 },
}

export const PIPELINE_TYPES = {
  ADMIN: 'admin',
  CONTENT: 'content',
  ABANDONED: 'abandoned_cart',
}
export const MESSAGE_TYPES = {
  FOOTER: 'footer',
  REPEAT: 'repeat',
  DELAYED: 'delayed',
  ABSOLUTE: 'absolute',
  RELATIVE: 'relative',
  ONBOARDING: 'onboarding',
}
export const PIPELINE_LABELS = {
  [PIPELINE_TYPES.ADMIN]: 'Admin',
  [PIPELINE_TYPES.CONTENT]: 'Content',
  [PIPELINE_TYPES.ABANDONED]: 'Abandoned cart',
}

export const USER_CONTENT_HISTORY_STATUSES = {
  finishedContent: {
    labelStatus: 'Completed',
    fill: 'var(--user-status-complete-theme)',
  },
  startedContent: {
    labelStatus: 'Incomplete',
    fill: 'var(--user-status-incomplete-theme)',
  },
  skippedContent: {
    labelStatus: 'Skipped',
    fill: 'var(--user-status-unopened-theme)',
  },
  deliveredContent: {
    labelStatus: 'Unopened',
    fill: 'var(--user-status-unopened-theme)',
  },
}

export const TYPE_SURVEY_SPECIFIC = 'absolute'
export const TYPE_SURVEY_RELATIVE = 'relative'
export const TYPE_SURVEY_EVENTS = 'event'

export const SURVEY_EVENTS = {
  CONTENT_DELIVERED: 'content_delivered',
}

export const TITLES_SURVEY_EVENTS = {
  [SURVEY_EVENTS.CONTENT_DELIVERED]: 'After delivered content',
}

export const DROPDOWN_DATA_SURVEY_EVENTS = [
  {
    title: TITLES_SURVEY_EVENTS[SURVEY_EVENTS.CONTENT_DELIVERED],
    value: SURVEY_EVENTS.CONTENT_DELIVERED,
  },
]

export const THEMES_ID = {
  meditation: process.env.REACT_APP_MEDITATION_ID,
}

export const DATE_FORMAT = 'MM.DD.YYYY'
export const MONTH_YEAR_FORMAT = 'YYYY-MM'
export const ISO_DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT_RANGE_PICKER = 'DD MMM YYYY'
export const DATE_FORMAT_WITH_TIME = 'MMM D, YYYY [at] hh:mm A'
export const DATE_FORMAT_MDY = 'MMM DD, YYYY'
export const DATE_FORMAT_MD = 'MMM DD'
export const TIME_FORMAT_12H = 'hh:mm A'

export const DAYS_OF_WEEK = [
  { label: 'Sunday', shortLabel: 'S', value: 'sunday' },
  { label: 'Monday', shortLabel: 'M', value: 'monday' },
  { label: 'Tuesday', shortLabel: 'T', value: 'tuesday' },
  { label: 'Wednesday', shortLabel: 'W', value: 'wednesday' },
  { label: 'Thursday', shortLabel: 'T', value: 'thursday' },
  { label: 'Friday', shortLabel: 'F', value: 'friday' },
  { label: 'Saturday', shortLabel: 'S', value: 'saturday' },
]

export const WORK_DAYS = [
  { label: 'Monday', shortLabel: 'M', value: 'monday' },
  { label: 'Tuesday', shortLabel: 'T', value: 'tuesday' },
  { label: 'Wednesday', shortLabel: 'W', value: 'wednesday' },
  { label: 'Thursday', shortLabel: 'T', value: 'thursday' },
  { label: 'Friday', shortLabel: 'F', value: 'friday' },
]

export const DELIVERY_METHODS = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  WEEKDAY: 'weekday',
  CUSTOM: 'custom',
}

export const DELIVERY_METHODS_INITIAL_INFO = [
  {
    title: 'Daily',
    value: DELIVERY_METHODS.DAILY,
    listDays: [{ label: 'Every day' }],
  },

  {
    title: 'Every weekday (Monday to Friday)',
    value: DELIVERY_METHODS.WEEKDAY,
    listDays: [
      {
        label: 'Every weekday',
      },
    ],
  },
  {
    title: 'Custom...',
    value: DELIVERY_METHODS.CUSTOM,
    listDays: DAYS_OF_WEEK,
  },
]

export const FEEDBACK_TYPES = {
  THEME: 'theme',
  ACCOUNT: 'account',
  SMS: 'sms',
}
export const FEEDBACK_TYPES_NAME = {
  [FEEDBACK_TYPES.THEME]: 'Content feedback',
  [FEEDBACK_TYPES.ACCOUNT]: 'Cancellation',
  [FEEDBACK_TYPES.SMS]: 'User SMS',
}
export const DATA_DROPDOWN_FEEDBACK_TYPES = [
  { type: FEEDBACK_TYPES.THEME, title: 'Content feedback' },
  { type: FEEDBACK_TYPES.ACCOUNT, title: 'Cancellation' },
  { type: null, title: 'All types' },
]

export const INPUT_TYPES = {
  OTP: 'INPUT_OTP',
  INPUT_TAG: 'INPUT_TAG',
  INPUT_TIME: 'INPUT_TIME',
  INPUT_FILE: 'INPUT_FILE',
  INPUT_MASK: 'INPUT_MASK',
  TEXT: 'INPUT_SIMPLE_TEXT',
  RADIO_BUTTON: 'RADIO_BUTTON',
  CHECKBOX: 'CHECKBOX',
  INPUT_DROPDOWN: 'INPUT_DROPDOWN',
  INPUT_RANGE: 'INPUT_RANGE',
  INPUT_SELECT_TIMEZONE: 'INPUT_SELECT_TIMEZONE',
  INPUT_DROPDOWN_PICKER: 'INPUT_DROPDOWN_PICKER',
  INPUT_DATE_TIME_RANGE: 'INPUT_DATE_TIME_RANGE',
  INPUT_DATE_TIME: 'INPUT_DATE_TIME',
  INPUT_WEEK_DAYS: 'INPUT_WEEK_DAYS',
  INPUT_DROPDOWN_DELIVERY_METHOD: 'INPUT_DROPDOWN_DELIVERY_METHOD',
  INPUT_CONFIG_DELIVERY_TIME_PICKER: 'INPUT_CONFIG_DELIVERY_TIME_PICKER',
}
export const STATUS_CELL_TYPES = {
  THEME: 'THEME',
  CONTENT: 'CONTENT',
  SUBSCRIPTION: 'SUBSCRIPTION',
}

export const SURVEY_QUESTION_TYPES = {
  OPEN_END: 'open_end',
  RATING_SCALE: 'rating_scale',
  SINGlE_CHOICE_SELECT: 'single_choice_select',
}
export const SURVEY_OPTION_KEY = 'option'

export const COUPON_DURATION = {
  FOREVER: 'forever',
  ONCE: 'once',
  REPEATING: 'repeating',
}
export const DISCOUNT_TYPES = {
  PERCENT: 'percent',
  AMOUNT: 'amount',
}

export const TABLE_ROW_TYPE = {
  TABLE_ROW_USER_ANSWER: 'TABLE_ROW_USER_ANSWER',
  TABLE_ROW_GIFT_PRICING: 'TABLE_ROW_GIFT_PRICING',
}

export const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i
export const URL_PATTERN =
  /^https:\/\/[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})((\/[^\s]*)?)$/

export const PHONE_PATTERN_REPLACE = /^(\+\d)(\d{3})(\d{3})(\d{4})$/
export const REPLACEMENT_PHONE = '$1 ($2) $3-$4'
export const URL_PHONE_PATTERN = /^\+1 *\(\d{3}\) \d{3}-\d{4}$/
export const PHONE_PATTERN = /(\+?1[ -]?)?\(?[2-9]\d\d\)?[ -]?[2-9]\d\d[ -]?\d{4}$/
export const PHONE_MASK = [
  '+',
  '1',
  ' ',
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const ADMIN_ROUTE_MENU = [
  { route: routes.users, text: 'Users', Icon: IconUsers },
  { route: routes.feedback, text: 'Feedback', Icon: VscFeedback },
  { route: routes.dashboard, text: 'Dashboard', Icon: SpaceDashboard },
  { route: routes.programs, text: 'Programs', Icon: BookContent },
  { route: routes.tags, text: 'Tags', Icon: IconTags },
  { route: routes.adminGiftCoupons, text: 'Gift pricing', Icon: Coupon },
]

export const DAYS_COUNT = Array.from({ length: 8 }, (_, i) => ({ title: i + 1 }))

export const ORDER_DIRECTION_ASC = 'asc'
export const ORDER_DIRECTION_DESC = 'desc'

export const TITLE_SURVEY_ANSWERS = [
  { title: '#', accessor: 'number' },
  { title: 'Full name', accessor: 'fullName' },
  { title: 'Answers', accessor: 'answers', className: 'text-start' },
]
export const TITLE_GIFT_COUPONS = [
  { title: '', accessor: 'status' },
  { title: '', accessor: 'link' },
  { title: '', accessor: 'image' },
  { title: 'Name', accessor: 'name' },
  { title: 'Terms', accessor: 'terms' },
  { title: 'Purchased', accessor: 'purchased' },
  { title: 'Created', accessor: 'createdDate' },
]

export const DROPDOWN_DATA_GIFT_STATUS = [
  { title: 'Active', value: true },
  { title: 'Disabled', value: false },
  { title: 'All Gifts', value: null },
]

export const INFO_NETWORK_ERROR = {
  errorStatus: 'Error',
  errorText: 'Network error',
  show: true,
}

export const USER_RECENT_ACTIVITIES_TYPES = {
  ACTIVATE_ACCOUNT: 'account_activated',
  APPLIED_PROMOTION_COUPON: 'applied_promotion_code', // Add theme name
  EXPIRED_PROMOTION_CODE: 'expired_promotion_code', // Add theme name
  LOGGED_IN: 'logged_in',
  ENTERED_BILLING_INFO: 'entered_billing_info',
  APPLIED_GIFT_COUPON: 'applied_gift_coupon',
  SUCCESSFUL_PAYMENT: 'successful_payment',
  FAILED_PAYMENT: 'failed_payment',
  SUBSCRIPTION_CREATED: 'subscription_created',
  SUBSCRIPTION_STATUS_UPDATED: 'subscription_status_updated',
  ACCOUNT_CANCELLED: 'account_cancelled',
  SIGNUP: 'signup',
  STARTED_SUBSCRIBING: 'started_subscribing',
}
export const STRIPE_PRODUCTS_TYPES = {
  SUBSCRIPTION: 'subscription',
  GIFT_COUPON: 'gift_coupon',
  OTHER: 'other',
  ONE_TIME_SUBSCRIPTION: 'one_time_subscription',
}

export const DOT_STYLE = {
  stroke: 'white',
  strokeWidth: 2,
  fill: 'var(--date-picker)',
  r: 4,
  strokeDasharray: '',
}

export const SETTINGS_RANDOM_COLOR = {
  luminosity: 'dark',
  alpha: 0.35,
  format: 'hex',
  hue: 'random',
}

export const OPENING_VARIABLE_CHARS = '{{'
export const CLOSING_VARIABLE_CHARS = '}}'

export const EXCEPT_TYPE_IMAGE = '.jpg,.gif,.png,.webp'

export const STATUS_DISCOUNT_CODE = {
  ACTIVE: 'active',
  EXPIRED: 'expired',
}

export const DISCOUNT_CODE_INFO = {
  [STATUS_DISCOUNT_CODE.ACTIVE]: {
    text: 'Active',
    bgColor: 'var(--active-color)',
    color: 'var(--active-color-text)',
  },
  [STATUS_DISCOUNT_CODE.EXPIRED]: {
    text: 'Expired',
    bgColor: 'var(--expired-color)',
    color: 'var(--expired-color-text)',
  },
}

export const FILTER_BY_SUBSCRIPTION_STATUSES = [
  { title: 'Free trial', value: SUBSCRIPTION_STATUSES.TRIAL },
  { title: 'Active', value: SUBSCRIPTION_STATUSES.ACTIVE },
  { title: 'Paused', value: SUBSCRIPTION_STATUSES.PAUSED },
  { title: 'Finished', value: SUBSCRIPTION_STATUSES.FINISHED },
  { title: 'Abandoned cart', value: SUBSCRIPTION_STATUSES.ABANDONED_CART },
  { title: 'Cancelled', value: SUBSCRIPTION_STATUSES.CANCELED },
  { title: 'Payment failed', value: SUBSCRIPTION_STATUSES.PAYMENT_FAILED },
]

export const FILTER_BY_RECEIVE_STATUS = [
  { title: 'Can send SMS', value: true },
  { title: 'Cannot send SMS', value: false },
  { title: 'All Statuses', value: null },
]
export const DROPDOWN_DATA_GIFT_STATUSES = [
  { title: 'Active', value: 'active' },
  { title: 'Expired', value: 'expired' },
]
export const DROPDOWN_DATA_THEME_ACTIVE_STATUS = [
  { title: 'Active', value: true },
  { title: 'Inactive', value: false },
]

export const MONTH_COLORS = {
  February: '#33958e',
  September: '#00aef0',
  March: '#01a550',
  October: '#bed731',
  April: '#b4ab36',
  November: '#f6951d',
  May: '#ee2124',
  July: '#ec078d',
  January: '#734d9e',
  December: '#916945',
  June: '#b2b2b2',
  August: '#0038ea',
}
export const CHART_WORK_WEEKEND_COLORS = ['var(--swatch--cta-blue)', 'var(--catalina-blue)']

export const SETTINGS_MESSAGE_TAG_PICKER = {
  menuClassName: 'menuInterestUser',
  getData: getAllTags,
  valueKey: 'id',
  creatable: false,
  cleanable: false,
  labelKey: 'name',
  className: 'tagsSelector messageTagSelector',
  placeholder: 'Choose message tags',
}

export const DEFAULT_SETTINGS_DROPDOWN_PICKER = {
  className: 'tagsSelector userInterest',
  menuClassName: 'menuInterestUser',
  labelKey: 'name',
  cleanable: false,
  creatable: false,
  valueKey: 'id',
}

export const BUTTON_KEY_BACKSPACE = 'Backspace'

export const DEFAULT_MESSAGE_CONSTANTS = {
  contactUrl: 'Contact',
  themeName: 'Theme name',
  firstName: 'User first name',
  profileLink: 'Link to profile',
  contentLink: 'Link to content',
  subscriptionLink: 'Link to subscription',
  themesList: "Show user's subscribed themes",
}

export const DEFAULT_VALUE_ONBOARDING_TEMPLATE = {
  themesList: 'Theme name, theme name, ...',
  profileLink: process.env.REACT_APP_LOGIN_URL,
}

export const ACHIEVEMENTS_TYPES = {
  TOTAL: 'total',
  STREAK: 'streak',
  ACTIONS: 'actions',
  GIFTED_COUPON: 'giftedCoupon',
  SENT_FEEDBACK: 'sentFeedback',
  RECEIVED_COUPON: 'receivedCoupon',
  FINISHED_THEME: 'finishedTheme',
}

export const ACHIEVEMENTS_TITLE_TYPES = {
  [ACHIEVEMENTS_TYPES.TOTAL]: '',
  [ACHIEVEMENTS_TYPES.ACTIONS]: 'Actions',
  [ACHIEVEMENTS_TYPES.STREAK]: 'Streaks',
}

export const ACHIEVEMENTS_INFO = {
  [ACHIEVEMENTS_TYPES.TOTAL]: {
    'total-1': {
      title: 'First Meditation',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/first-meditation.webp',
    },
    'total-5': {
      title: '5 Meditations',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/5-total.webp',
    },
    'total-10': {
      title: '10 Meditations',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/10-total.webp',
    },
    'total-15': {
      title: '15 Meditations',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/15-total.webp',
    },
    'total-25': {
      title: '25 Meditations',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/15-total.webp',
    },
    'total-50': {
      title: '50 Meditations',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/50-total.webp',
    },
    'total-100': {
      title: '100 Meditations',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/100-total.webp',
    },
    'total-200': {
      title: '200 Meditations',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/200-total.webp',
    },
    'total-300': {
      title: '300 Meditations',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/300-total.webp',
    },
    'total-400': {
      title: '400 Meditations',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/400-total.webp',
    },
    'total-500': {
      title: '500 Meditations',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/500-total.webp',
    },
  },
  [ACHIEVEMENTS_TYPES.STREAK]: {
    'streak-3': {
      title: '3-Day Streak',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/3-streak.webp',
    },
    'streak-5': {
      title: '5-Day Streak',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/5-streak.webp',
    },
    'streak-7': {
      title: '7-Day Streak',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/7-streak.webp',
    },
    'streak-14': {
      title: '14-Day Streak',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/14-streak.webp',
    },
    'streak-20': {
      title: '20-Day Streak',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/20-streak.webp',
    },
    'streak-50': {
      title: '50-Day Streak',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/50-streak.webp',
    },
    'streak-75': {
      title: '75-Day Streak',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/75-streak.webp',
    },
    'streak-100': {
      title: '100-Day Streak',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/100-streak.webp',
    },
    'streak-200': {
      title: '200-Day Streak',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/200-streak.webp',
    },
    'streak-300': {
      title: '300-Day Streak',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/300-streak.webp',
    },
    'streak-400': {
      title: '400-Day Streak',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/400-streak.webp',
    },
    'streak-500': {
      title: '500-Day Streak',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/500-streak.webp',
    },
  },
  [ACHIEVEMENTS_TYPES.ACTIONS]: {
    finished_theme: {
      title: 'Finish theme',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/finished-program.webp',
    },
    gifted_coupon: {
      title: 'Gift coupon',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/gift-coupon.webp',
    },
    received_coupon: {
      title: 'Receive coupon',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/receive-coupon.webp',
    },
    sent_feedback: {
      title: 'Send Feedback',
      imageSrc: 'https://s3.amazonaws.com/pendle.com/meditation/achievements/feedback.webp',
    },
  },
}

export const THEMES_COLORS = [
  '#98FF98',
  '#203e5f',
  '#ffcc00',
  '#504aa7',
  '#220e24',
  '#16498A',
  '#F1B351',
  '#2f576e',
  '#D3D3D3',
  '#ADD8E6',
  '#2E86C1',
]

export const ANSWERS_COLORS = [
  '#FF5733',
  '#7C8B8E',
  '#2E86C1',
  '#17a52a',
  '#F4D03F',
  '#BA4A00',
  '#8E44AD',
  '#138D75',
]

export const CONTENT_STATUSES = {
  FINISHED_CONTENT: 'finishedContent',
  STARTED_CONTENT: 'startedContent',
  OPENED_CONTENT: 'openedContent',
  SKIPPED_CONTENT: 'skippedContent',
  DELIVERED_CONTENT: 'deliveredContent',
}
export const CONTENT_STATUSES_DETAILS = {
  [CONTENT_STATUSES.FINISHED_CONTENT]: {
    label: 'Completed',
    fill: 'var(--user-status-complete-theme)',
  },
  [CONTENT_STATUSES.STARTED_CONTENT]: {
    label: 'Incomplete',
    fill: 'var(--user-status-incomplete-theme)',
  },
  [CONTENT_STATUSES.OPENED_CONTENT]: {
    label: 'Opened',
    fill: 'var(--user-status-opened-theme)',
  },
  [CONTENT_STATUSES.SKIPPED_CONTENT]: {
    label: 'Skipped',
    fill: 'var(--user-status-unopened-theme)',
  },
  [CONTENT_STATUSES.DELIVERED_CONTENT]: {
    label: 'Delivered',
    fill: 'var(--user-status-unopened-theme)',
  },
}

export const LEGEND_LIST_USER_HISTORY_CONTENT = [
  {
    value: 'Unopened / Skipped',
    id: 'deliveredContent',
    color: 'var(--user-status-unopened-theme)',
  },
  {
    value: 'Incomplete',
    id: 'startedContent',
    color: 'var(--user-status-incomplete-theme)',
  },
  {
    value: 'Completed',
    color: 'var(--user-status-complete-theme)',
    id: 'finishedContent',
  },
]

export const DEFAULT_TOAST_MESSAGES = {
  pending: 'Loading...',
  success: 'Action completed successfully!',
  error: {
    render({ data }) {
      const responseData = data?.response?.data?.data
      return typeof responseData === 'string'
        ? responseData
        : 'Something was happened, wait until we fix it'
    },
  },
}

export const SENT_BY = {
  ADMIN: 'admin',
  USER: 'user',
}

export const WEBSOCKET_CONNECTION_CHANNEL = {
  ADMIN: 'admin',
  ADMIN_CHAT: 'admin-chat',
  ADMIN_CHATS: 'admin-chats',
}

export const WEBSOCKET_EVENTS = {
  READY: 'ready',
  CLOSE: 'close',
  ERROR: 'error',
  LEAVE_ERROR: 'leave_error',
  READ_MESSAGE: 'read_message',
  SYNC_CHAT_INFO: 'sync_chat_info',
  UPDATED_COUPON: 'updated_coupon',
  CREATED_COUPON: 'created_coupon',
  RECEIVED_MESSAGE: 'received_message',
  TOGGLE_IS_STARRED: 'toggled_is_starred',
  UPDATED_SUBSCRIPTION: 'updated_subscription',
  SYNC_UNREAD_NOTIFICATION_COUNT: 'sync_unread_notifications_count',
  SYNC_TWILIO_STATUS: 'sync_twilio_status',
}
export const WEBSOCKET_STATES = {
  CLOSED: 'closed',
  PENDING: 'pending',
  OPEN: 'open',
}

export const CHAT_LIST_TABS = {
  ALL: 'All',
  STARRED: 'Starred',
  ATTENTION: 'ATTENTION',
}

export const DROPDOWN_GENDERS = [{ title: 'Male' }, { title: 'Female' }, { title: 'Other' }]

export const DROPDOWN_QUESTION_TYPES = [
  { type: SURVEY_QUESTION_TYPES.SINGlE_CHOICE_SELECT, title: 'Single choice select' },
  { type: SURVEY_QUESTION_TYPES.OPEN_END, title: 'Open-End question' },
  { type: SURVEY_QUESTION_TYPES.RATING_SCALE, title: 'Rating Scale' },
]

export const LIMIT_QUESTION_OPTION = 5

export const DROPDOWN_PICKER_PIPELINES_DATA = [
  {
    title: 'Main content',
    value: PIPELINE_TYPES.CONTENT + MESSAGE_TYPES.RELATIVE,
    globalType: 'Content',
    hasAccess: () => true,
  },
  {
    title: 'Content onboarding',
    value: PIPELINE_TYPES.CONTENT + MESSAGE_TYPES.ONBOARDING,
    globalType: 'Content',
    hasAccess: () => true,
  },
  {
    title: 'Footer',
    value: PIPELINE_TYPES.CONTENT + MESSAGE_TYPES.FOOTER,
    globalType: 'Content',
    hasAccess: () => true,
  },
  {
    title: 'Content absolute',
    value: PIPELINE_TYPES.CONTENT + MESSAGE_TYPES.ABSOLUTE,
    globalType: 'Content',
    hasAccess: () => true,
  },
  {
    title: 'Admin content',
    value: PIPELINE_TYPES.ADMIN + MESSAGE_TYPES.RELATIVE,
    globalType: 'Admin',
    hasAccess: () => true,
  },
  {
    title: 'Admin absolute',
    value: PIPELINE_TYPES.ADMIN + MESSAGE_TYPES.ABSOLUTE,
    globalType: 'Admin',
    hasAccess: () => true,
  },
  {
    title: 'Repeated messages',
    value: PIPELINE_TYPES.CONTENT + MESSAGE_TYPES.REPEAT,
    globalType: 'Admin',
    hasAccess: (item) => item?.activePrice?.type === PRICE_TYPES.RECURRING,
  },
  {
    title: 'Abandoned onboarding',
    value: PIPELINE_TYPES.ABANDONED + MESSAGE_TYPES.ONBOARDING,
    globalType: 'Abandoned',
    hasAccess: (item) => item?.activePrice?.type === PRICE_TYPES.RECURRING,
  },
  {
    title: 'Abandoned pipeline',
    value: PIPELINE_TYPES.ABANDONED + MESSAGE_TYPES.DELAYED,
    globalType: 'Abandoned',
    hasAccess: (item) => item?.activePrice?.type === PRICE_TYPES.RECURRING,
  },
]
export const PIPELINE_TABLES_SETTINGS = {
  [PIPELINE_TYPES.CONTENT + MESSAGE_TYPES.RELATIVE]: {
    label: 'Main content',
    getMessagesInfo: getThemeMessages,
    requestOptions: {
      type: PIPELINE_TYPES.CONTENT,
      messageType: MESSAGE_TYPES.RELATIVE,
    },
    dateType: MESSAGE_TYPES.RELATIVE,
    withContent: true,
    withPagination: true,
    enableDND: true,
    showModalDeliverySettings: true,
  },
  [PIPELINE_TYPES.CONTENT + MESSAGE_TYPES.FOOTER]: {
    label: 'Footer',
    getMessagesInfo: getThemeMessages,
    requestOptions: {
      type: PIPELINE_TYPES.CONTENT,
      messageType: MESSAGE_TYPES.FOOTER,
    },
    withContent: false,
    withPagination: false,
    enableDND: false,
    showModalDeliverySettings: false,
  },
  [PIPELINE_TYPES.CONTENT + MESSAGE_TYPES.ONBOARDING]: {
    label: 'Content onboarding',
    getMessagesInfo: getThemeMessages,
    requestOptions: {
      type: PIPELINE_TYPES.CONTENT,
      messageType: MESSAGE_TYPES.ONBOARDING,
    },
    withContent: true,
    withPagination: false,
    enableDND: false,
    showModalDeliverySettings: false,
  },
  [PIPELINE_TYPES.CONTENT + MESSAGE_TYPES.ABSOLUTE]: {
    label: 'Absolute',
    getMessagesInfo: getThemeMessages,
    requestOptions: {
      type: PIPELINE_TYPES.CONTENT,
      messageType: MESSAGE_TYPES.ABSOLUTE,
    },
    dateType: MESSAGE_TYPES.ABSOLUTE,
    withContent: true,
    withPagination: true,
    enableDND: false,
    showModalDeliverySettings: false,
  },
  [PIPELINE_TYPES.ADMIN + MESSAGE_TYPES.RELATIVE]: {
    label: 'Admin content',
    getMessagesInfo: getThemeMessages,
    requestOptions: {
      type: PIPELINE_TYPES.ADMIN,
      messageType: MESSAGE_TYPES.RELATIVE,
    },
    dateType: MESSAGE_TYPES.RELATIVE,
    withContent: true,
    withPagination: true,
    enableDND: true,
    showModalDeliverySettings: true,
  },
  [PIPELINE_TYPES.ADMIN + MESSAGE_TYPES.ABSOLUTE]: {
    label: 'Admin absolute',
    getMessagesInfo: getThemeMessages,
    requestOptions: {
      type: PIPELINE_TYPES.ADMIN,
      messageType: MESSAGE_TYPES.ABSOLUTE,
    },
    dateType: MESSAGE_TYPES.ABSOLUTE,
    withContent: true,
    withPagination: true,
    enableDND: false,
    showModalDeliverySettings: false,
  },
  [PIPELINE_TYPES.CONTENT + MESSAGE_TYPES.REPEAT]: {
    label: 'Repeated messages',
    getMessagesInfo: getRepeatedContentMessages,
    deleteMessageRoute: deleteRepeatedContentMessage,
    requestOptions: {
      type: PIPELINE_TYPES.CONTENT,
      messageType: MESSAGE_TYPES.REPEAT,
    },
    withContent: true,
    withPagination: true,
    enableDND: false,
    showModalDeliverySettings: false,
  },
  [PIPELINE_TYPES.ABANDONED + MESSAGE_TYPES.DELAYED]: {
    label: 'Abandoned pipeline',
    getMessagesInfo: getThemeMessages,
    requestOptions: {
      type: PIPELINE_TYPES.ABANDONED,
      messageType: MESSAGE_TYPES.DELAYED,
    },
    dateType: MESSAGE_TYPES.RELATIVE,
    withContent: true,
    withPagination: true,
    enableDND: true,
    showModalDeliverySettings: false,
  },
  [PIPELINE_TYPES.ABANDONED + MESSAGE_TYPES.ONBOARDING]: {
    label: 'Abandoned onboarding',
    getMessagesInfo: getThemeMessages,
    requestOptions: {
      type: PIPELINE_TYPES.ABANDONED,
      messageType: MESSAGE_TYPES.ONBOARDING,
    },
    withContent: true,
    withPagination: false,
    enableDND: false,
    showModalDeliverySettings: false,
  },
}
export const THEME_STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ARCHIVED: 'archived',
}
export const THEME_STATUSES_SETTINGS = {
  [THEME_STATUSES.ACTIVE]: {
    mainColor: 'var(--main-active-theme-status)',
    label: 'Active',
  },
  [THEME_STATUSES.INACTIVE]: {
    mainColor: 'var(--main-inactive-theme-status)',
    label: 'Inactive',
  },
  [THEME_STATUSES.ARCHIVED]: {
    mainColor: 'var(--main-archived-theme-status)',
    label: 'Archived',
  },
}

export const REGISTRATION_STEPS = [
  {
    step: 1,
    title: 'Enter Your Phone Number',
    description:
      'Please enter your phone number to receive a confirmation code for verifying your registration.',
    isCanBack: false,
    isCanSkip: false,
    showIcon: false,
    showStepNumber: '1',
    activeSteps: [1, 2],
  },
  {
    step: 2,
    title: 'Complete Verification',
    description: 'Enter the code we sent to your phone to verify your registration.',
    shortDescription: 'Enter verification code',
    isCanBack: true,
    isCanSkip: false,
    showIcon: true,
    Icon: Lock,
    showStepNumber: '1',
    activeSteps: [1, 2],
  },
  {
    step: 3,
    title: 'Set delivery time',
    description:
      'Please choose your timezone and delivery schedule to personalize your experience.',
    shortDescription: 'Enter delivery preferences',
    isCanBack: false,
    isCanSkip: false,
    showIcon: true,
    Icon: Settings,
    showStepNumber: '2',
    activeSteps: [3],
  },
  {
    step: 4,
    title: 'Complete registration',
    description:
      'Please provide your payment details to complete the registration process and unlock your subscription.',
    shortDescription: 'Enter payment info',
    isCanBack: true,
    isCanSkip: false,
    showIcon: true,
    Icon: CreditCard,
    showStepNumber: '3',
    activeSteps: [4],
  },
]

export const APPEARANCE_STRIPE_FORM_REGISTRATION = {
  theme: 'stripe',
  variables: {
    fontFamily: 'Arial, sans-serif',
    fontSizeBase: '16px',
    colorPrimary: '#000000',
    colorBackground: '#f9f0e4',
    colorText: '#000000',
    colorDanger: '#fa755a',
    colorWarning: '#ffcc00',
    borderRadius: '12px',
    spacingUnit: '5px',
    tabIconColor: '#FFFFFF',
    tabIconHoverColor: '#e7e4e4',
  },
  rules: {
    '.Input': {
      fontSize: '16px',
      lineHeight: '20px',
      borderRadius: '12px',
      width: '100%',
      border: '2px solid #ced4da',
      padding: '8px 15px',
      color: '#000000',
      backgroundColor: '#f9f0e4',
      transition: 'all .5s',
    },
    '.Input:focus': {
      boxShadow: '0 0 0 0.25rem rgba(183, 178, 172, 0.3)',
      color: '#000000',
      border: '2px solid #ced4da',
      backgroundColor: '#f9f0e4',
    },
    '.Tab': {
      backgroundColor: '#3e5772',
      color: '#FFFFFF',
    },
    '.Tab:focus': {
      borderColor: '#3e5772',
    },
    '.Tab:active': {
      borderColor: '#3e5772',
    },
    '.Tab:hover': {
      color: '#FFFFFF',
    },
    '.Tab--selected': {
      boxShadow: '0 0 0 0.25rem rgba(183, 178, 172, 0.3)',
      backgroundColor: '#4a709a',
      color: '#FFFFFF',
      borderColor: '#4a709a',
    },
    '.Tab--selected:hover': {
      color: '#FFFFFF',
    },
    '.Tab--selected:focus': {
      borderColor: '#4a709a',
      boxShadow: '0 0 0 0.25rem rgba(183, 178, 172, 0.3)',
    },
    '.Tab--selected:active': {
      borderColor: '#4a709a',
    },
    '.TabIcon': {
      fill: '#FFFFFF',
    },
    '.TabIcon--selected': {
      fill: '#FFFFFF',
    },
    '.Label': {
      color: '#000000',
    },
    '.Block': {
      border: 'none',
    },
  },
}

export const REGISTRATION_GTM_EVENTS = {
  PHONE_INPUT_STARTED: 'phone_input_started',
  PHONE_CONFIRMATION: 'phone_confirmation',
  SUCCESSFUL_PURCHASE: 'successful_purchase',
}

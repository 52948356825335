import { toast } from 'react-toastify'

import {
  DISCOUNT_TYPES,
  COUPON_DURATION,
  ALL_ACTIVE_SUBSCRIPTION_STATUSES,
  ALL_NON_RENEW_SUBSCRIPTION_STATUSES,
  SUBSCRIPTION_STATUSES,
} from '../constants'
import { applyCoupon } from '../../api/stripe'

export const formatDiscountLabel = ({ discountType, discountAmount }) => {
  return discountType === DISCOUNT_TYPES.AMOUNT ? `$${discountAmount}` : `${discountAmount}%`
}

export const generateSubscriptionInfoText = ({
  couponDuration,
  defaultPrice,
  discountType,
  discountAmount,
  discountedPrice,
  discountMonthDuration,
}) => {
  const discount = formatDiscountLabel({ discountType, discountAmount })

  if (couponDuration === COUPON_DURATION.ONCE) {
    return `Your new subscription price is $${discountedPrice} for the first payment, then you will pay $${defaultPrice}.`
  }

  if (couponDuration === COUPON_DURATION.REPEATING && discountMonthDuration) {
    const discountYearDuration = Math.ceil(discountMonthDuration / 12)
    return `Your new subscription price is $${discountedPrice} for ${discountYearDuration} year(s), then you will pay $${defaultPrice}.`
  }

  if (discountedPrice === defaultPrice) {
    return `Subscription price is $${defaultPrice}.`
  }

  return `Your new subscription price is $${discountedPrice}. You got ${discount} off.`
}

export const handleCouponApplication = async (
  { couponValue, themeId },
  updateSubscriptionTextInfo
) => {
  const toastId = toast.loading('Loading...')
  try {
    const { data } = await applyCoupon({ promotionCode: couponValue, themeId })
    const newSubscriptionTextInfo = generateSubscriptionInfoText({
      ...data?.price,
      defaultPrice: data?.price?.old,
      discountedPrice: data?.price?.new,
      couponDuration: data?.price?.duration,
      discountMonthDuration: data?.price?.durationInMonths,
    })
    updateSubscriptionTextInfo(newSubscriptionTextInfo)
    toast.update(toastId, {
      render: (
        <>
          Coupon <b>{couponValue}</b> applied successfully!
        </>
      ),
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
    })

    return data?.price
  } catch (error) {
    toast.update(toastId, {
      render: (
        <>
          Coupon <b>{couponValue}</b> is invalid.
        </>
      ),
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
    })
    return null
  }
}

export const hasUserActiveThemeSubscription = (status) => {
  return [...ALL_ACTIVE_SUBSCRIPTION_STATUSES, ...ALL_NON_RENEW_SUBSCRIPTION_STATUSES].includes(
    status
  )
}

export const shouldSubscribe = (status) => {
  return [SUBSCRIPTION_STATUSES.ABANDONED_CART, SUBSCRIPTION_STATUSES.CANCELED].includes(status)
}
